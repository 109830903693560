<template>
  <div class="campaignCard card">
    <ul class="campaignCard__user">
      <span class="campaignCard__user--status"></span>
      <li class="campaignCard__user--icon">
        <img
          :src="`${$config.IMG_HOST}/50x50/${campaign.logo}`"
          alt=""
          class="img-fluid"
        />
      </li>
      <li>
        {{ campaign.name }}
        <div v-if="campaign.subscription">
          <span v-if="campaign.subscription.state">{{
            campaign.subscription.state
          }}</span>
          <span class="onMobile" v-if="campaign.subscription.timestamp">{{
            numberToDate(campaign.subscription.timestamp)
          }}</span>
        </div>
      </li>
    </ul>
    <ul class="campaignCard__date" v-if="campaign.subscription">
      <li v-if="campaign.subscription.timestamp !== null">
        {{ numberToDate(campaign.subscription.timestamp) }}
      </li>
      <li>
        <a href="#" v-if="campaign.price">{{ campaign.price }}</a>
      </li>
    </ul>
    <router-link
      v-if="islink"
      :to="`/brand/my-campaign/${campaign.id}/${friendURL(campaign.name)}`"
      class="abs-link"
    />
  </div>
</template>

<script>
export default {
  props: {
    campaign: [Object, Array],
    islink: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.campaignCard {
  position: relative;
  padding: rem(14px);
  padding-right: rem(20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 6px !important;
  margin-bottom: rem(14px);
  &__user {
    display: flex;
    align-items: center;
    list-style: none;
    &--status {
      position: absolute;
      width: 14px;
      height: 14px;
      background: #24d694;
      border-radius: 50%;
      top: 50px;
      z-index: 1;
      left: 50px;
    }
    &--icon {
      position: relative;
      width: 50px;
      min-width: 50px;
      height: 50px;
      background: #d8b4fa;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: rem(15px);
      overflow: hidden;
      // img {
      // 	height: 100%;
      // 	width: 100%;
      // }
    }
    li {
      font-size: rem(18px);
      font-weight: 500;
      color: var(--textPrimary);
      span {
        display: block;
        color: var(--textSecondary);
        font-size: rem(14px);
        font-weight: 500;
      }
    }
  }
  &__date {
    list-style: none;
    display: flex;
    align-items: center;
    li {
      font-size: rem(14px);
      font-weight: 600;
      color: var(--primary);
      padding: 0 rem(16px);
      a {
        position: relative;
        font-weight: 700;
        color: var(--primary);
        z-index: 10;
        &::before {
          position: absolute;
          content: "";
          background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.538' height='23.913' viewBox='0 0 13.538 23.913'%3E%3Cpath id='Path_217' data-name='Path 217' d='M4871.167,592.811l9.916,9.683-9.916,9.987' transform='translate(-4869.045 -590.69)' fill='none' stroke='%239637f1' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          width: 14px;
          height: 24px;
          top: -1px;
          right: -20px;
          transition: 0.3s ease-in-out;
          @media screen and (max-width: 991px) {
            top: -3px;
          }
        }
        &:hover {
          @media screen and (min-width: 1025px) {
            opacity: 0.8;
            &::before {
              right: -23px;
            }
          }
        }
      }
      &:first-child {
        position: relative;
        @media screen and (max-width: 991px) {
          display: none;
        }
        &::before {
          position: absolute;
          content: "";
          height: 60px;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          background: #c9ced6;
          border: 1px solid #c9ced6;
          border-radius: 50px;
          overflow: hidden;
        }
      }
    }
  }
  .onMobile {
    display: none;
    font-size: rem(14px);
    color: var(--primary);
    @media screen and (max-width: 991px) {
      display: block !important;
    }
  }
}
</style>
